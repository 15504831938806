<template>
  <el-footer class="app-footer">
    <div class="footer-content">
      <div class="left-content">备案号:ICP备2022049398号-1</div>
      &nbsp;&nbsp;
      <div class="right-content">
        Copyright© 2023 宋子宪免费API接口平台 版权所有 邮箱:blog@songzixian.com
      </div>
    </div>
  </el-footer>
</template>
 
<style scoped>
.app-footer {
  padding: 20px 0;
  background-color: transparent;
  color: #333;
  border-top: 1px solid #e5e5e5; /* 添加一个上边界来分隔内容和页脚 */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.footer-content {
  max-width: 1158px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-content,
.right-content {
  font-size: 14px;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

.left-content:hover,
.right-content:hover {
  color: #888; /* 鼠标悬停时的颜色变化 */
}

.left-content {
  text-align: left;
  padding-left: 15px;
}

.right-content {
  text-align: right;
  padding-right: 15px;
}
</style>
