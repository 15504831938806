// src/utils/http.js
import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api.songzixian.com/',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(config => {
  // 例如添加 token
  // config.headers['Authorization'] = `Bearer ${yourToken}`;
  return config;
}, error => {
  return Promise.reject(error);
});

instance.interceptors.response.use(response => {
  return response.data;  // 这里我们直接返回响应数据
}, error => {
  // 你可以在这里统一处理错误
  // 例如：显示一个错误提示
  console.error('API error:', error);
  return Promise.reject(error);
});

export default instance;