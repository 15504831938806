import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'; 
import * as icons from '@element-plus/icons-vue';


import App from './App.vue'
import router from './router' // 引入路由配置


const app = createApp(App)
app.use(ElementPlus)
app.use(router) // 使用路由
app.mount('#app')
app.provide('icons', icons);

