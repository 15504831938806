<template>
  <div class="sponsor-container">
    <div class="title-section">
      <h1>赞助列表</h1>
    </div>
    <div class="sponsors-grid">
      <div class="sponsor-card" v-for="sponsor in sponsors" :key="sponsor.id">
        <div class="card-content">
          <div class="sponsor-logo">
            <!-- 在这里添加赞助商logo，暂时使用名字的首字母 -->
            <div class="logo-placeholder">{{ sponsor.name[0] }}</div>
          </div>
          <div class="sponsor-details">
            <h2>{{ sponsor.name }}</h2>
            <p>赞助金额：{{ sponsor.amount }}</p>
            <p>赞助日期：{{ sponsor.date }}</p>
            <span class="sponsor-status" :class="getStatusClass(sponsor.status)">
              {{ sponsor.status }}
            </span>
          </div>
        </div>
      </div>
    </div>
       <!-- 可以在这里添加一个通用的付款二维码 -->
       <div class="payment-qr-code">
      <img src="@/assets/img/sponsor.png" alt="付款二维码">
    </div>

  </div>
</template>

<script>
export default {
  name: 'SponsorPage',
  data() {
    return {
      sponsors: [
        { id: 1, name: '赞助商A', amount: '￥5', date: '2023-01-01', status: '活跃' },
        { id: 2, name: '赞助商B', amount: '￥10', date: '2023-02-01', status: '活跃' },
        { id: 3, name: '赞助商C', amount: '￥500', date: '2023-03-01', status: '活跃' },
        // 更多赞助商数据
      ]
    };
  },
  methods: {
    getStatusClass(status) {
      return {
        'status-active': status === '活跃',
        'status-maintenance': status === '维护',
        'status-inactive': status === '停用'
      };
    }
  }
};
</script>

<style scoped>
.sponsor-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: #333;
}

.title-section h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

.sponsors-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.sponsor-card {
  width: 300px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
  transition: transform 0.3s ease-in-out;
}

.sponsor-card:hover {
  transform: translateY(-5px);
}

.card-content {
  padding: 1.5rem;
  background: white;
}

.sponsor-logo {
  text-align: center;
  margin-bottom: 1.5rem;
}

.logo-placeholder {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: #eee;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 1.5rem;
  color: #333;
}

.sponsor-details h2 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.sponsor-status {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  color: white;
  font-size: 0.875rem;
  text-align: center;
  margin-top: 0.5rem;
}

.status-active {
  background-color: #38a169;
}

.status-maintenance {
  background-color: #ed8936;
}

.status-inactive {
  background-color: #e53e3e;
}


.payment-qr-code {
  text-align: center;
  margin: 2rem 0; /* 顶部和底部的外边距调整为2rem，以便与周围的元素对齐 */
}

.payment-qr-code img {
  width: 500px; /* 设置宽度与.sponsor-card一致 */
  height: auto; /* 高度自动调整以保持图片比例 */
  border: 1px solid #ccc; /* 二维码边框（可选） */
}

</style>
