<template>
  <div class="about-container">
    <div class="title-section">
      <h1>关于我们</h1>
      <p class="description">
        API-领先的高性能免费API数据服务平台。我们专注于提供稳定、高速的数据接口服务，助您轻松实现各类应用。
        本api服务系统是自研系统，采用java以及多种设计模式和多线程开发的高性能服务，长期稳定运行，免费使用!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
};
</script>

<style scoped>
.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: #333;
}

.title-section h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.description {
  font-size: 1rem;
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 2rem;
}
</style>
