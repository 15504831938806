// router/index.js
import HomePage from '@/components/HomePage.vue';
import { createRouter, createWebHistory } from 'vue-router'
import ApiDetail from '@/components/ApiDetail.vue'
import Sponsor from '@/components/Sponsor.vue'
import About from '@/components/About.vue'

const routes = [
    { path: '/', name: 'HomePage', component: HomePage },
    { path: '/api-detail/:id', name: 'api-detail', component: ApiDetail },
    { path: '/sponsor', name: 'sponsor', component: Sponsor },
    { path: '/about', name: 'about', component: About }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
