<!-- src/App.vue -->
<template>
  <div>
    <app-header></app-header>
    <div class="app-container">
      <router-view></router-view> <!-- 这里将展示具体页面的内容，如HomePage或ApiDetail -->
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style>/* 为 AppHeader 添加特定的样式，确保它的宽度为100% */
app-header {
  width: 100%;
  background-color: #ffffff;
}

.app-container {
  width: 100%; /* 宽度100% */
  min-height: 100vh; /* 确保内容至少和视口一样高 */
  display: flex;
  flex-direction: column; /* 垂直排列子元素 */
  align-items: center; /* 水平居中 */
  background-color: #f0f2f5; /* 设置背景颜色为#f0f2f5 */
}

.content-wrapper {
  max-width: 1200px; /* 设置最大宽度 */
  width: 100%; /* 宽度100% */
  display: flex;
  flex-direction: column; /* 垂直排列子元素 */
}

main-content, app-footer {
  background-color: #ffffff; /* 设置为白色背景 */
  padding: 15px; /* 内边距 */
  margin: 15px 0; /* 添加一些间隔 */
}


</style>
