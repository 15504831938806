<template>
  <header class="app-header">
    <div class="container">
      <!-- Logo -->
      <div class="logo">
        <a href="https://api.songzixian.com">
          <!-- Replace the content below with your logo -->
          <i class="fa fa-fire text-primary"></i> 宋子宪API
        </a>
      </div>
      <!-- Navigation -->
      <nav>
        <ul class="menu">
          <!-- Creating links for each navigation item, these are just examples -->
          <li><a href="/about">关于我们</a></li>
          <li><a href="/sponsor" target="_blank">赞助我</a></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  // 如果有需要的话，您可以在这里添加JavaScript逻辑
};
</script>

<style scoped>
.app-header {
  position: fixed; /* 添加 fixed 属性使头部固定 */
  top: 0; /* 确保它固定在页面顶部 */
  left: 0; /* 与页面左边对齐 */
  right: 0; /* 与页面右边对齐 */
  background: #ffffff;
  padding: 14px 0; /* 适当调整内边距 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* 使用了更明显的阴影效果 */
  width: 100%;
  position: sticky;
  z-index: 1000; /* 确保头部总是在其他元素之上 */
}

/* 由于头部是固定的，内容会向上移动填充空间。我们需要下面的规则来添加额外的间距 */
body {
  padding-top: 50px; /* 根据头部的具体高度调整。这会防止主要内容被头部遮挡。 */
}

/* 响应式容器 */
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1150px;
}

/* 添加媒体查询以改进小屏幕设备的导航体验 */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .menu {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

.logo a {
  color: #333;
  text-decoration: none;
  font-size: 1.5em;
  transition: color 0.3s ease; /* 平滑颜色过渡 */
}

.menu {
  list-style: none;
  display: flex;
  gap: 20px;
}

.menu li a {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease; /* 平滑颜色过渡 */
}

.menu li a:hover,
.logo a:hover {
  /* 应用同样的悬停效果 */
  color: #007bff; /* 您的品牌颜色，或其他醒目颜色 */
}
</style>
