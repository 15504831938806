<!-- src/components/HomePage.vue -->
<template>
  <div class="content-wrapper">
    <div class="main-content">
      <!-- 第一个大卡片 -->
      <el-card class="box-card">
        <!-- 搜索部分 -->
        <div class="search-container">
          <el-input
            v-model="searchKeyword"
            placeholder="请输入您要查找的API名称"
            class="search-input"
            @keyup.enter="handleSearch"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            class="search-btn ml-2"
            >搜索</el-button
          >
        </div>
      </el-card>

      <!-- 第二个大卡片 -->
      <!-- <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>Card name</span>
          </div>
        </template> -->
      <el-row :gutter="12">
        <el-col :span="8" v-for="(item, index) in listData" :key="index">
          <!-- 为<el-card>添加@click事件 -->
          <el-card
            class="rounded-content-card box-card clickable-card"
            shadow="always"
            @click="handleCardClick(item.apiId)"
          >
            <!-- 标签容器 -->
            <div class="card-tag-wrapper">
              <!-- 到时候替换成接口即可-->
              <el-tag
                v-if="item.apiStatus === 'ACTIVE'"
                class="ml-2"
                type="success"
                >正常</el-tag
              >
              <el-tag
                v-else-if="item.apiStatus === 'MAINTENANCE'"
                class="ml-2"
                type="warning"
                >维护</el-tag
              >
              <el-tag
                v-else-if="item.apiStatus === 'EXPIRED'"
                class="ml-2"
                type="danger"
                >停用</el-tag
              >
            </div>
            {{ item.apiName || "默认内容" }}
          </el-card>
        </el-col>
      </el-row>

      <!-- </el-card> -->
    </div>
  </div>
</template>


<script>
import http from "@/utils/http";

export default {
  name: "HomePage",
  // 你的脚本代码（如果有的话）
  data() {
    return {
      listData: null,
      searchKeyword: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData(searchKeyword = "") {
      try {
        const params = {};
        if (searchKeyword) {
          params.apiName = searchKeyword; // 当有关键词时，添加apiName参数
        }
        const result = await http.get("/api-metadata/list", { params });
        this.listData = result.data;
        console.log("123", this.listData);
      } catch (error) {
        // 你可以在这里处理或显示错误，但通常已经在 http.js 中进行了统一处理
      }
    },

    handleSearch() {
      console.log("Search keyword:", this.searchKeyword);
      this.fetchData(this.searchKeyword); // 传入关键词到fetchData方法中
    },
    handleCardClick(apiId) {
      console.log("apiId:", apiId);
      this.$router
        .push({ name: "api-detail", params: { id: apiId } })
        .catch((err) => {
          console.error("Navigation error:", err);
        });
    },
  },
};
</script>

<style>
.content-wrapper {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.main-content {
  background-color: #f0f2f5;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.box-card {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  background-color: #ffffff;
}

.el-row > .el-col {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 100px;
}

.rounded-content-card {
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
}

.card-tag-wrapper {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.search-input {
  width: 576px;
  height: 42px;
  border-radius: 21px;
}

.search-btn {
  width: 50px;
  height: 42px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
}

.search-btn i {
  color: #ffffff;
}

.clickable-card {
  transition: all 0.3s;
  cursor: pointer;
}

.clickable-card:hover {
  background-color: #f7f9fc;
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.clickable-card:focus {
  outline: none;
  border: 2px solid #4caf50;
}

.clickable-card:hover .card-tag-wrapper .el-tag {
  color: #333;
}

@media (max-width: 768px) {
  .search-input {
    width: calc(100% - 60px);
    margin-right: 10px;
  }

  .search-btn {
    width: 50px;
  }

  .rounded-content-card {
    padding: 10px;
    font-size: 0.9em;
  }

  .el-row > .el-col {
    width: 100%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-menu {
    display: none;
  }

  /* 大屏幕（桌面）：一行显示3个卡片 */
.el-row > .el-col {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding: 5px; /* 为卡片之间提供一些空间 */
}

/* 小屏幕（手机）：一行显示1个卡片 */
@media (max-width: 768px) {
  .el-row > .el-col {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 5px; /* 为卡片之间提供一些空间 */
  }

  /* 在小屏幕上可能还想调整其他样式 */
  .search-input, .search-btn {
    width: 100%; /* 让搜索输入和按钮在小屏幕上宽度为100% */
    border-radius: 4px; /* 调整为更适合小屏幕的边角圆滑度 */
  }
}
}
</style>
