<template>
  <div class="content-wrapper">
    <div class="main-content">
      <el-card class="custom-card">
        <div class="custom-card__content">
          <div class="custom-card__icon mb-2">
            <i class="fa fa-2x fa-gift"></i>
          </div>
          <h4 class="custom-card__title mb-1">{{ apiDetail.apiName }}</h4>
          <p class="custom-card__description">{{ apiDetail.apiDesc }}</p>
        </div>
      </el-card>

      <!-- API详情卡片 -->
      <el-card class="box-card">
        <el-row class="custom-row" :gutter="30">
    <!-- API URL部分 -->
    <el-col :span="12">
      <div class="info-block">
    <label class="info-label">接口地址</label>
    <div class="code-style">
        {{ apiDetail.apiPath }}    

    </div>
</div>

    </el-col>

    <!-- 请求方法部分 -->
    <el-col :span="6">
        <div class="info-block">
            <label class="info-label">请求方法</label>
            <div class="code-style">
                {{ apiDetail.httpMethod }}
            </div>
        </div>
    </el-col>

    <!-- 响应格式部分 (假设响应总是JSON) -->
    <el-col :span="6">
        <div class="info-block">
            <label class="info-label">返回格式</label>
            <div class="code-style">
                JSON
            </div>
        </div>
    </el-col>
</el-row>

      </el-card>

      <!-- 请求参数表格 -->
      <el-card class="box-card">
        <label class="tile-label">请求参数</label>
        <el-table
          :data="apiDetail.paramsDesc"
          stripe
          border
          style="width: 100%"
        >
          <el-table-column prop="paramName" label="名称" width="300" />
          <el-table-column prop="isRequired" label="必填" width="150" />
          <el-table-column prop="dataType" label="类型"  width="150"/>
          <el-table-column prop="description" label="说明" />
        </el-table>

        <!-- 请求示例 -->
        <label class="tile-label">请求示例</label>
        <div class="code-container">
          <code>{{ apiDetail.requestSample }}</code>
        </div>
      </el-card>

      <!-- 示例卡片 -->
      <el-card class="box-card">
        <label class="tile-label">响应示例</label>
        <div class="code-container">
          <pre><code>{{ apiDetail.responseSample }}</code></pre>
        </div>

        <!-- 响应参数说明 -->
        <label class="tile-label">响应参数说明</label>
        <el-table
          :data="apiDetail.responseDesc"
          stripe
          border
          style="width: 100%"
        >
          <el-table-column prop="paramName" label="名称" width="400px" />
          <el-table-column prop="isRequired" label="必需" width="150" />
          <el-table-column prop="dataType" label="类型" width="250px"/>
          <el-table-column prop="description" label="说明" />
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
import http from "@/utils/http";
export default {
  name: "ApiDetail",
  computed: {
    // 从路由参数中计算API的ID
    apiId() {
      return this.$route.params.id;
    },
  },
  // 当组件被创建时，获取API的详细信息
  created() {
    this.fetchApiDetails(this.apiId);
  },
  data() {
    return {
      apiDetail: {}, // 存储API的详细信息
    };
  },
  methods: {
    // 通过API ID获取API的详细信息
    async fetchApiDetails(id) {
      try {
        const response = await http.get(
          "/api-metadata/getApiMetadataById/" + id
        );
        this.apiDetail = response.data;
        // 如果API响应包含请求参数和响应参数的信息，更新表格的数据
        if (this.apiDetail.requestParams) {
          this.paramTableData = this.apiDetail.requestParams;
        }
        if (this.apiDetail.responseParams) {
          this.responseTableData = this.apiDetail.responseParams;
        }
      } catch (error) {
        // 处理错误，可能会显示一个消息或执行其他操作
      }
    },
    // 复制API URL到剪贴板
    copyUrl() {
      this.copyToClipboard(this.apiDetail.apiPath);
    },
    // 通用的复制到剪贴板的方法
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$message({ message: "复制成功!", type: "success" });
        })
        .catch(() => {
          this.$message.error("复制失败!");
        });
    },
  },
};
</script>

<style scoped>

/* 通用样式，适用于所有尺寸的屏幕 */
.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}


.main-content {
  margin: 20px 0;
}

.box-card {
  margin-bottom: 20px;
}

.input-group,
.tile-group {
  margin-bottom: 20px;
}

.input-label,
.tile-label {
  font-weight: bold;
  margin-bottom: 10px;
}

.code-container {
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.content-wrapper {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.main-content {
  background-color: #f0f2f5;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.box-card {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  background-color: #ffffff;
}

/** 接口信息排版 */
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

/**接口信息 */
.input-label {
  display: block;
  margin-top: 10px; /* 上方间隔 */
  margin-bottom: 10px; /* 下方间隔 */
}

/**接口信息 */
.tile-label {
  display: block;
  margin-top: 20px; /* 上方间隔 */
  margin-bottom: 10px; /* 下方间隔 */
}

.input-group {
  margin-bottom: 10px;
}

/**代码块*/
.code-container {
  padding: 12px;
  background-color: #f3f3f3;
  border: 1px solid #e0e0e0; /* 加强边框 */
  border-radius: 5px;
  margin: 10px 0;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 添加阴影 */
  overflow-x: auto;
}

.code-container pre {
  margin: 0;
  padding: 0; /* 之前的值是 8px 0，现在我们将其设为0来移除内部间距 */
}

.code-container code {
  font-family: Consolas, "Courier New", Courier, monospace;
  font-size: 0.95em; /* 字体稍微小一些，看起来更加整洁 */
  white-space: pre-wrap;
  color: #333; /* 更深的文字颜色 */
}

.box-card.ip-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* 垂直和水平居中内容 */
  padding: 20px; /* 卡片内部间距 */
  border: 1px solid #e0e0e0; /* 浅色边框 */
  border-radius: 10px; /* 圆角 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻微的阴影 */
  transition: box-shadow 0.3s ease; /* 过渡效果 */
}

.box-card.ip-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* 鼠标悬停时的阴影效果 */
}

.box-card.ip-card .fa-gift {
  font-size: 3rem;
  margin-bottom: 20px;
  opacity: 0.8;
}

.box-card.ip-card h4 {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #333; /* 深灰色文字 */
}

.box-card.ip-card .fs-sm {
  font-size: 1rem;
  margin-bottom: 0; /* 保持与描述文字之间的间距 */
  font-weight: 400; /* 正常字体权重 */
  color: #555; /* 中等灰色文字 */
}

/**ip信息 */
.custom-card {
  background-color: #6891fc; /* 调整了背景颜色的不透明度 */
  border-radius: 20px;
  height: 150px; /* 设置卡片高度 */
  margin-bottom: 20px;
  padding: 10px 15px; /* 修改了内边距 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white; /* 设置字体颜色为白色 */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.custom-card__content {
  text-align: center;
}

.custom-card__icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 10px; /* 增加了图标下方的间距 */
}

.custom-card__title {
  font-weight: 600;
  font-size: 1.4em; /* 字体稍微增大了 */
  margin-bottom: 6px; /* 调整了下方的间距 */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* 添加了文字阴影，使其在浅色背景上更为突出 */
}

.custom-card__description {
  font-size: 0.95em;
  font-weight: 400;
  opacity: 0.95; /* 使描述文字更加明亮 */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* 添加了文字阴影 */
}




.info-block {
    margin-bottom: 20px;
}

.info-label {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

.code-style {
    padding: 8px 12px;
    background-color: #f3f3f3;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-family: Consolas, "Courier New", Courier, monospace;
    white-space: nowrap;
    overflow-x: auto;
}


/* 媒体查询：当屏幕宽度小于768像素时 */
.card {
  /* 假设卡片有一个最大宽度和一些内边距 */
  max-width: 300px;
  padding: 20px;
  margin: 0 auto; /* 让卡片在大屏幕上居中 */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* 当屏幕宽度小于768像素时，我们希望卡片宽度为100% */
@media (max-width: 768px) {
  .card {
    width: 100%;
    max-width: none; /* 取消最大宽度的限制 */
    margin: 0; /* 移除边距让卡片延伸到屏幕边缘 */
    box-sizing: border-box; /* 确保内边距不会使卡片宽度超出100% */
  }
}





</style>